import Vue from 'vue';
import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ModifySwsModerateViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.modify-sws-moderate.modify-sws-moderate-dialog';

  readonly view: Vue;

  is_disabled = true;

  confirm_moderate = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  constructor(view: Vue) {
    this.view = view;
  }

  confirmModerate = () => {
    this.is_disabled = !this.confirm_moderate;
  }

  nextStep = () => {
    this.view.$emit('nextStep');
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }
}
