



























































import { Component, Vue } from 'vue-property-decorator';
import ModifySwsModerateViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/modify-sws-moderate/modify-sws-moderate-view-model';

@Component({ name: 'ModifySwsModerate' })
export default class ModifySwsModerate extends Vue {
  modify_sws_moderate_model = Vue.observable(new ModifySwsModerateViewModel(this));
}
